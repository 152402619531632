var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"edit-menu-sidebar","visible":_vm.isEditMenuSidebarActive,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},on:{"change":function (val) { return _vm.$emit('update:is-edit-menu-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"NavigationIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.menuItem.menu_id ? "编辑导航" : "添加导航")+" ")],1),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-form',{staticClass:"p-2"},[_c('b-form-group',{attrs:{"label":"导航名称","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","trim":""},model:{value:(_vm.menuItem.title),callback:function ($$v) {_vm.$set(_vm.menuItem, "title", $$v)},expression:"menuItem.title"}})],1),(_vm.menuItem.menu_id)?_c('b-form-group',{attrs:{"label":"导航ID","label-for":"menu-id"}},[_c('b-form-input',{attrs:{"id":"menu-id","autofocus":"","disabled":"","trim":""},model:{value:(_vm.menuItem.menu_id),callback:function ($$v) {_vm.$set(_vm.menuItem, "menu_id", $$v)},expression:"menuItem.menu_id"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"父级导航ID","label-for":"p_menu_id"}},[_c('b-form-input',{attrs:{"id":"p_menu_id"},model:{value:(_vm.menuItem.p_menu_id),callback:function ($$v) {_vm.$set(_vm.menuItem, "p_menu_id", $$v)},expression:"menuItem.p_menu_id"}})],1),_c('b-form-group',{attrs:{"label":"导航类型","label-for":"menu_type"}},[_c('v-select',{staticClass:"rounded",attrs:{"id":"menu_type","clearable":false,"disabled":!!_vm.menuItem.menu_id,"options":_vm.menuItem.menu_id
							? [
									{ text: '目录', value: 0 },
									{ text: '展示', value: 1 },
									{ text: '管理', value: 2 },
									{ text: '仪表板', value: 3 },
									{ text: '外链', value: 4 } ]
							: [
									{ text: '目录', value: 0 },
									{ text: '仪表板', value: 3 },
									{ text: '外链', value: 4 } ],"reduce":function (text) { return text.value; },"label":"text"},model:{value:(_vm.menuItem.type),callback:function ($$v) {_vm.$set(_vm.menuItem, "type", $$v)},expression:"menuItem.type"}})],1),_c('b-form-group',{attrs:{"label":"所属项目","label-for":"project_id"}},[_c('v-select',{staticClass:"rounded",attrs:{"id":"project_id","clearable":false,"options":[
						{ text: 'All', value: 0 },
						{
							text: '当前',
							value: _vm.project_selected,
						} ],"reduce":function (text) { return text.value; },"disabled":"","label":"text"},model:{value:(_vm.menuItem.project_id),callback:function ($$v) {_vm.$set(_vm.menuItem, "project_id", $$v)},expression:"menuItem.project_id"}})],1),_c('b-form-group',{attrs:{"label":"导航图标","label-for":"icon"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"icon","trim":""},model:{value:(_vm.menuItem.icon),callback:function ($$v) {_vm.$set(_vm.menuItem, "icon", $$v)},expression:"menuItem.icon"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":_vm.menuItem.icon}})],1)],1)],1),(_vm.menuItem.type === 4)?_c('b-form-group',{attrs:{"label":"外链网址","label-for":"route_params"}},[_c('b-input',{attrs:{"id":"route_params","placeholder":_vm.$t('Link Url')},model:{value:(_vm.menuItem.route_params.src),callback:function ($$v) {_vm.$set(_vm.menuItem.route_params, "src", $$v)},expression:"menuItem.route_params.src"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"排序","label-for":"route_order"}},[_c('b-form-input',{attrs:{"id":"route_order","trim":""},model:{value:(_vm.menuItem.order),callback:function ($$v) {_vm.$set(_vm.menuItem, "order", $$v)},expression:"menuItem.order"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t("Submit"))+" ")]),_c('b-button',{attrs:{"size":"sm","type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }